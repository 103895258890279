.card-proposal {
  background-color: $white-card;
  .card-title {
    color: $blue-color;
    font-weight: 700;
  }
  .card-subtitle {
    color: $blue-color;
    margin-bottom: 8px;
  }

  .right {
    text-align: end;
  }
  &.danger {
    background-color: $beige-background;
  }
}

.card-block {
  background-color: $blue-light-background;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  border-radius:12px;

  .title {
    color: $blue-color;
    font-weight: 700;
  }

  &.sold {
    background-color: $green-light-background;
  }
}
