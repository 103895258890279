img {
  max-width: 100%;
  border-radius: $border-radius-small;
}
.img-raised {
  box-shadow: $box-shadow-raised;
}
.img-avatar {
  object-fit: cover;
  object-position: top center;
  border-radius:50%;
  width: 80px;
  height: 80px;
  border-width: 0;
}